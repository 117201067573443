export const Messages = {
  titleOneUpdate: 'New update available',
  titleMultipleUpdates: 'New updates available',
  newVersions: 'New Versions',
  readMore: 'Read more',
  fullReleaseNotes: 'Full release notes here',
  goToUpdatesPage: 'Go to updates page',
  snooze: 'Dismiss',
  readyForAnUpdate: 'Ready for an upgrade?',
  updateDescription:
    "We've streamlined our update process with a new, user-friendly interface. One click gets you all the latest improvements.",
};
